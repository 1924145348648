<template>
<nav class="mobile">
  <router-link to="/" class="nav-item ">
    <i data-feather="home"></i><span>الرئيسية</span>
  </router-link>
  
  <router-link to="/Categorie" class="nav-item ">
    <i data-feather="server"></i><span>التصنيفات</span>
  </router-link>
  
  <router-link to="/Poets" class="nav-item ">
    <i data-feather="users"></i><span>الشعراء</span>
  </router-link>
  
  <router-link to="/MostViewed" class="nav-item">
    <i data-feather="bell"></i><span>الاكثر مشاهدة</span>
  </router-link>

</nav>
</template>

<script>
export default {
  name: 'AppBottomNavbar',
  mounted() {
    const s = document.createElement('script');
      s.type = 'text/javascript';
      s. src = '/static/js/father.js';
      document.body.appendChild(s);
  }
}
</script>

<style scoped>
           
nav{
  display: flex;
justify-content: space-around;
background-color: #018181;
padding: 12px;
position: fixed;
bottom: 0;
width: 100%;
}

.nav-item{
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  padding: 12px 16px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0);
}

.nav-item span{
  width: 0;
  overflow: hidden;
}

.nav-item:focus{
  text-decoration: none;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  transition: .2s;
}

.nav-item:focus span{
  width: 100%;
  margin-right: 8px;
  transition: .2s;
}

*:focus{
  outline: none;
  border: none;
}
</style>